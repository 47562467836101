import { useMemo } from "react";

import { Variables, VariablesTransformed } from "store/resources/actions/variables/types";
import { ModuleVariable, ModuleVariableTransformed } from "store/resources/actions/calculationWorkflow/types";

export const useVariablesTransform = <T1 extends Variables | ModuleVariable, T2 extends VariablesTransformed | ModuleVariableTransformed>(
    data: T1[],
    itemsInRow: number
): T2[][] | undefined => {
    return useMemo(() => {
        if (!data) {
            return;
        }

        let index = 0;

        // TODO: Complete generic implementation
        let itemRow: any[] = [];
        let newItems: any[][] = [];

        data.forEach((element) => {
            const values = element.possibleValues || [];
            const isText = values.length === 0;

            if (index === itemsInRow) {
                index = 0;
                itemRow = [];
            }

            if (index === 0) {
                newItems.push(itemRow);
            }

            itemRow.push({
                ...element,
                isText,
                values: values.map((v) => ({
                    label: v.description,
                    value: v.name,
                })),
            });

            index += 1;
        });

        return newItems;
    }, [data, itemsInRow]);
};

export const useVariablesTransformByGroupName = (data: Variables[]): VariablesTransformed[][] | undefined => {
    return useMemo(() => {
        if (!data) {
            return;
        }

        let groupName = "";

        let itemRow: VariablesTransformed[] = [];
        let newItems: VariablesTransformed[][] = [];

        data.forEach((element) => {
            const values = element.possibleValues || [];
            const isText = values.length === 0;

            if (element.groupName && groupName !== element.groupName) {
                itemRow = [];
            }

            if (element.groupName && groupName !== element.groupName) {
                groupName = element.groupName;
                newItems.push(itemRow);
            }

            itemRow.push({
                ...element,
                isText,
                values: values.map((v) => ({
                    label: v.description,
                    value: v.name,
                })),
            });
        });

        return newItems;
    }, [data]);
};
